export const strings = {
  upTo: 'iki',
  redirecting: 'Nukreipiama...',
  startingFrom: 'nuo',
  consultation: '+ konsultacija',
  written: '+ komentaras',
  years: '{age} m.',
  error: {
    required: 'Būtina sutikti',
    RequiredFillOut: 'Būtina užpildyti',
    quotaReached: {
      title: 'Apgailestaujame, tačiau nemokamų tyrimų šiandien neliko',
      description: 'Kviečiame Jus pamėginti rytoj',
    },
    feedback: {
      title: 'Apgailestaujame, tačiau įvyko klaida',
      description: 'Maloniai prašome palikti įvertinimą vėliau',
    },
    common: {
      title: 'Apgailestaujame, tačiau įvyko klaida',
      description: 'Maloniai prašome bandyti vėliau',
    },
    pageNotFound: {
      title: 'Puslapis nerastas',
      description: 'Patikrinkite puslapio adresą ir pamėginkite dar kartą.',
      goHome: 'Grįžti į pradžią',
    },
    fieldIsRequired: 'Šis laukas yra privalomas',
    youMustSelect: 'Pasirinkti privaloma',
    terms: {
      required: 'Su paslaugų taisyklėmis sutikti yra privaloma',
    },
    agreeResearch: {
      required: 'Jeigu nesutiksite, mes negalėsime Jums atlikti testų ir pateikti rekomendacijų',
    },
    agreementForScienceResearch: {
      required: 'Jeigu nesutiksite, mes negalėsime Jums atlikti testų ir pateikti rekomendacijų',
    },
    selectedPatientId: {
      required: 'Pasirinkti pacientą privaloma',
    },
    firstName: {
      required: 'Vardas yra privalomas',
    },
    lastName: {
      required: 'Pavardė yra privaloma',
    },
    email: {
      required: 'El.pašto adresas yra privalomas',
      isUsed:
        'Šiuo el. pašto adresu jau yra sukurtas vartotojas. Prašome pasinaudoti prisijungimo forma arba naudoti kitą el. pašto adresą.',
    },
    phone: {
      required: 'Telefonas yra privalomas',
    },
    bank: {
      required: 'Pasirinkite mokėjimo būdą',
    },
    password: {
      minLength: 'Slaptažodis turi būti bent {length} simbolių',
      same: 'Slaptažodžiai turi sutapti',
    },
    birthDate: {
      required: 'Gimimo data privaloma',
    },
    gender: {
      required: 'Nurodyti lytį yra privaloma',
    },
    personCode: {
      required: 'Asmens kodas privalomas',
      incorrect: 'Įveskite asmens kodą. Įvestas kodas yra nekorektiškas.',
      length: 'Turi būti vienuolikos simbolių ilgio',
    },
    agreeRemoteConsultation: {
      required: 'Jeigu nesutiksite, mes negalėsime Jums suteikti nuotolinių konsultacijų',
    },
    agreeChildResponsibility: {
      required: 'Jūs privalote būti įgaliotas asmuo, jei norite užregistruoti vaiką.',
    },
    agreeTerms: {
      required: 'Būtina sutikti su naudojimosi taisyklėmis',
    },
    YourChildCode: {
      required: 'Asmens kodas privalomas',
    },
    address: {
      required: 'Nurodyti adresą yra privaloma',
    },
    backendError: 'Įvyko klaida. Bandykite vėliau.',
  },
  loading: {
    wait: 'Palaukite..',
    wait_until_check: 'Palaukite, kol gausime mokėjimo patvirtinimą iš banko',
    wait_until_sign_check: 'Palaukite, kol gausime dokumento pasirašymo patvirtinimą',
  },
  button: {
    close: 'Uždaryti',
    continue: 'Tęsti',
    start: 'Pradėti',
    goBack: 'Grįžti atgal',
    buy: 'Pirkti',
    order: 'Užsakyti',
    understood: 'Supratau',
    pay: 'Mokėti',
    completedOrder: 'Užsakyti',
    check: 'Tikrinti',
    login: 'Prisijungti',
    preview: 'Peržiūrėti',
    createAccount: 'Kurti paskyrą',
    createPatient: 'Kurti pacientą',
    sendReminder: 'Siųsti priminimą',
    changePassword: 'Pakeisti slaptažodį',
    signOrder: 'Pasirašyti',
    send: 'Išsiųsti',
    upload: 'Įkelti',
    complete: 'Baigti',
    pdf: 'Atsisiųsti PDF',
  },
  products: {
    CONSULTATION: {
      allergens: '0',
      title: 'Nuotolinė gydytojo alergologo konsultacija',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite nuotolinę konsultaciją',
      description:
        'Pokalbio metu įvertinami simptomai, bei tikimybė, kad juos sukelia alergija. Parenkami tyrimai ir sudaromas pirminis gydymo planas.',
      bullets: [
        'Gydytojo alergologo vertinimas ir rekomendacijos raštu',
        'Galimybė užduoti papildomus klausimus',
        '20min video skambutis su gydytoju alergologu',
      ],
    },
    FULL: {
      allergens: '295',
      title: 'Išsami alergijų ištyrimo programa',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite išsamų ištyrimą',
      description:
        'Tyrimas ištiria įsijautrinimą beveik 300 alergenų, kurie apima praktiškai visus alergenus, kurie gali sukelti greitojo tipo alergines reakcijas.',
      bullets: [
        '295 alergenų kraujo testas (+ iki 10 patikslinamųjų tyrimų)',
        'Alergologo vertinimas ir rekomendacijos',
        'Išsamus alergenų profilio aprašymas',
        'Galimybė užduoti papildomus klausimus',
        '20min video skambutis su gydytoju alergologu',
      ],
      review: {
        auto: {
          title: 'Automatinis tyrimo įvertinimas',
          price: '+0 €',
          description: 'Įvertinsime, kokie alergenai tikėtina provokuoja jūsų įvardintus simptomus.',
        },
        written: {
          title: 'Gydytojo vertinimas raštu',
          price: '+29 €',
          description:
            'Gydytojas alergologas įvertins ir pakomentuos jūsų tyrimo rezultatus, bei paskirs rekomendacijas raštu.',
        },
        consultation: {
          title: 'Gydytojo vertinimas + konsultacija',
          price: '+49 €',
          description:
            'Aptarsite tyrimo rezultatus ir gydymo planą su gydytoju alergologu gyvai nuotolinės konsultacijos metu ir taip pat gausite įvertinimą raštu.',
        },
      },
    },
    SCREENER: {
      allergens: '{allergensCount}',
      title: 'Pirminė alergijų ištyrimo programa',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite pirminį ištyrimą',
      description:
        'Tyrimas ištiria įsijautrinimą {allergensCount} alergenams, kurie sukelia 95% greitojo tipo alerginių reakcijų.',
      bullets: [
        '{allergensCount} alergenų kraujo testas',
        'Gydytojo alergologo vertinimas ir rekomendacijos raštu',
        'Galimybė užduoti papildomus klausimus',
        '20min video skambutis su gydytoju alergologu',
      ],
    },
    SCREENER_ASIT: {
      allergens: '{allergensCount}',
      title: 'AllergyChip',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite AllergyChip ištyrimą',
      description:
        'Tyrimas ištiria įsijautrinimą {allergensCount} alergenams, kurie sukelia 95% greitojo tipo alerginių reakcijų.',
      bullets: [
        '{allergensCount} alergenų kraujo testas',
        'Gydytojo alergologo vertinimas ir rekomendacijos raštu',
        'Galimybė užduoti papildomus klausimus',
      ],
      review: {
        auto: {
          title: 'Automatinis tyrimo įvertinimas',
          price: '+0 €',
          description: 'Įvertinsime, kokie alergenai tikėtina provokuoja jūsų įvardintus simptomus.',
        },
        written: {
          title: 'Gydytojo vertinimas raštu',
          price: '+29 €',
          description:
            'Gydytojas alergologas įvertins ir pakomentuos jūsų tyrimo rezultatus, bei paskirs rekomendacijas raštu.',
        },
        consultation: {
          title: 'Gydytojo vertinimas + konsultacija',
          price: '+49 €',
          description:
            'Aptarsite tyrimo rezultatus ir gydymo planą su gydytoju alergologu gyvai nuotolinės konsultacijos metu ir taip pat gausite įvertinimą raštu.',
        },
      },
    },
    FREE_SCREENER_ASIT: {
      allergens: '66',
      title: 'Pirminė alergijų ištyrimo programa',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite pirminį ištyrimą',
      description: 'Turite konkrečių klausimų apie alergiją ir jos gydymą? Mūsų gydytojai padės juos atsakyti.',
      bullets: ['Gydytojo alergologo vertinimas ir rekomendacijos raštu', 'Galimybė užduoti papildomus klausimus'],
    },
    FREE_SCREENER: {
      allergens: '28',
      title: 'Pirminė alergijų ištyrimo programa',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite pirminį ištyrimą',
      description: 'Turite konkrečių klausimų apie alergiją ir jos gydymą? Mūsų gydytojai padės juos atsakyti.',
      bullets: [
        'Gydytojo alergologo vertinimas ir rekomendacijos raštu',
        'Galimybė užduoti papildomus klausimus',
        '20min video skambutis su gydytoju alergologu',
      ],
    },
    IMMUNOTHERAPY_PLAN: {
      allergens: '295',
      title: 'Imunoterapija planas + konsultacija',
      titleResearch: 'Išsamus alergijos ištyrimas imunoterapijos plano sudarymui',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite gydymo plano sudarymą',
      description: '..',
      bullets: ['..'],
    },
    TREATMENT_PLAN: {
      allergens: '0',
      title: 'Imunoterapijos gydymas 3 mėnesiam',
      titleResearch: '',
      titleProduct6month: 'Imunoterapijos gydymas 6 mėnesiai',
      orderTitle: 'Užsisakykite nuotolinį imunoterapijos gydymą',
      description: '..',
      bullets: ['..'],
    },
    FOOD_ID: {
      allergens: '{allergensCount}',
      title: 'Maisto netoleravimo tyrimas',
      titleResearch: '',
      titleProduct6month: '',
      orderTitle: 'Užsisakykite Maisto netoleravimo ištyrimą',
      description: 'Tyrimas ištiria įsijautrinimą {allergensCount} maisto produktam',
      bullets: ['{allergensCount} alergenų kraujo testas'],
      chat: {
        heading: 'Turite klausimų apie savo mitybą ar maisto netoleravimą?',
      },
      legend: {
        description:
          'Maisto netoleravimo tyrimas matuoja IgG tipo antikūnų skaičių kraujyje. Šio tipo įsijautrinimas yra siejamas su maisto produktų netoleravimu. Kuo aukštenis įsijautrinimas, tuo didesnė tikimybė, kad atitinkamas maisto produktas gali provokuoti jūsų simptomus. Maisto netoleravimas diagnozuojamas tada, kai tyrimas rodo per didelį įsijautrinimą ir tas produktas provokuoja jūsų simptomus.',
      },
      review: {
        auto: {
          title: 'Konsultacijos nenoriu',
          price: '+0 €',
          description: 'Gaukite tyrimo interpretaciją ir rekomendacijas raštu.',
        },
        written: {
          title: 'Gydytojo vertinimas raštu',
          price: '+29 €',
          description:
            'Gydytojas alergologas įvertins ir pakomentuos jūsų tyrimo rezultatus, bei paskirs rekomendacijas raštu.',
        },
        consultation: {
          title: 'Pridėti konsultaciją',
          price: '+49 €',
          description: 'Po ištyrimo dietistas atsakys į jūsų klausimus ir padės sudaryti mitybos pokyčių planą.',
        },
      },
    },
  },
  features: {
    ourClinic: 'Allergomedica',
    privateClinic: 'Privati klinika',
    visits: {
      title: 'Vizitų skaičius',
      hint1: 'Kraujo pridavimui artimiausioje klinikoje',
      hint2: 'Priduoti kraują ir sužinoti atsakymą',
    },
    timeToResult: {
      title: 'Laikas iki galutinio atsakymo nuo pirmos registracijos',
      tier1: '7 d.d.',
      tier2: 'nuo 10 d.d.',
    },
    fullResearch: {
      title: 'Molekulinis kraujo tyrimas',
    },
    screenerResearch: {
      title: 'Išsamus alergijos kraujo tyrimas',
    },
    detailedProfile: {
      title: 'Išsamus jūsų alergijos profilis paruoštas gydytojo alergologo',
    },
    recommendations: {
      title: 'Išsamios rašytinos rekomendacijos',
    },
    questions: {
      title: 'Galimybė vėliau užduoti naujus klausimus sau patogiu laiku',
    },
  },
  product: {
    title: 'Užsisakykite išsamų tyrimą',
    text: 'Mūsų programos pagalba galėsite detaliai įvertinti ar patiriami simptomai yra keliami alergeno, bei kokie alergenai šiuos simptomus kelia. Pateiksime išsamią alergenų analizę, bei rekomendacijas ir gydymo būdus.',
    recommended: 'Rekomenduojame',
    comingSoon: 'Jau greitai',
    select: 'Rinktis',
  },
  allergo_expert: {
    title: 'Išsami alergijų diagnostinė programa',
    bullets: [
      'Alergologo vertinimas ir rekomendacijos',
      'Išsamus alergenų profilio aprašymas',
      'Nuotolinė konsultacija klausimams atsakyti',
      '295 alergenų kraujo testas (+ iki 10 patikslinamųjų tyrimų)',
      'Jei kils daugiau klausimų, turėsite galimybę užduoti klausimus mūsų komandai',
    ],
  },
  short_test: {
    scale_text: [
      'Nevargina',
      'Lengvai vargina',
      'Vidutiniškai vargina',
      'Stipriai vargina',
      'Ypatingai stipriai vargina',
    ],
  },
  shortTestAnswer: {
    title: 'Ačiū, kad užpildėte klausimyną.',
    description: {
      research:
        'Atsižvelgdamas į jūsų atsakymus, gydytojas alergologas parinks tiksliausias tyrimo metodikas, o gavęs tyrimų rezultatus pritaikys jums rekomendacijas.',
      consultation: 'Jūsų atsakymai leis gydytojui įsigilinti į jūsų atvejį prieš konsultaciją.',
    },
    weNeedYourAttention: 'Norime atkreipti Jūsų dėmesį',
    bookConsultation: 'Rezervuoti laiką konsultacijai',
    benefitsTitle: 'Ką sužinosite?',
    benefits: {
      SCREENER: [
        'Kokiems iš {allergensCount} populiariausių alergenų esate įsijautrinę',
        'Kokie alergenai tikėtina provokuoja jūsų įvardintus simptomus, o kurie yra kliniškai nereikšmingi',
        'Kaip galite pagerinti gyvenimo kokybę, pakoreguodami savo gyvenimo būdą',
        'Kokias turite gydymo galimybes',
      ],
      FULL: [
        'Kokiems iš 295 populiariausių alergenų ir jų molekulėms esate įsijautrinę',
        'Kokie alergenai tikėtina provokuoja jūsų įvardintus simptomus, o kurie yra kliniškai nereikšmingi',
        'Kaip galite pagerinti gyvenimo kokybę, pakoreguodami savo gyvenimo būdą',
        'Kokias turite gydymo galimybes, ar jums tinka imunoterapija',
      ],
    },
    viewSample: {
      title: 'Peržiūrėkite kaip atrodo pavyzdinis tyrimo rezultatas',
      button: 'Peržiūrėti',
    },
    comparison: {
      title: 'Palyginkite Allergomedica su privačiomis klinikomis',
      included: 'Įtraukta',
      notIncluded: 'Neįtraukta',
    },
    defaultProductTitle: 'Išsami alergijų diagnostinė programa',
  },
  allergyEvaluation: {
    subscribeAnswer: {
      title: 'Įveskite savo el. pašto adresą',
      description:
        'Tam, kad lengvai pasiektumėte klausimyno atsakymą, nurodytų el.pašto adresu, mes jums atsiųsime atsakymo nuorodą.',
      buttonText: 'Siųsti',
    },
  },
  shortAnswer: {
    hello: 'Sveiki,',
    title: 'Jums parengėme jūsų alergijos įvertinimą',
    description:
      'Įvertinimas parengtas remiantis 2879 žmonių statistika, kurie jau pasinaudojo Allergomedica paslaugomis. Remiantis šiais duomenimis įvertinome jūsų pateiktus simptomus ir pateikiame alergijos vertinimą.',
    possibility: {
      title: 'Greitojo tipo alergijos tikimybė',
      items: {
        veryLow: 'Labai žema',
        low: 'Žema',
        medium: 'Vidutinė',
        high: 'Aukšta',
        veryHigh: 'Labai aukšta',
      },
      hint: 'Įvertinimas gali padėti nuspręsti ar verta išsitirti dėl alergijos, tačiau neturėtų būti naudojamas kaip medicinė diagnozė.',
    },
    allergens: {
      title: 'Dažniausi alergenai',
      text: 'Tai yra alergenai, kurie dažniausia sukelia jūsų nurodytus simptomus.',
      textNoAllergens: 'Jūsų įvardinti simptomai dažniausiai nėra provokuojami greitojo tipo alergijos reakcijų.',
    },
    moreBlock: {
      title: 'Kas toliau?',
      items: {
        low: 'Tikimybė, kad jūsų simptomai provokuojami greitojo tipo alerginės reakcijos yra maža. Rekomenduojame kreiptis į atitinkamos srities gydytoją, siekiant įvertinti patiriamų simptomų kilmę.',
        high: 'Yra tikimybė, kad jūsų įvardintus simptomus provokuoja greitojo tipo alerginė reakcija. Norėdami pasitikrinti ir būti ramūs, rekomenduojame atlikti pirminį alergijos tyrimą.',
        veryHigh:
          'Labai tikėtina, kad jūsų įvardintus simptomus provokuoja greitojo tipo alerginė reakcija. Tačiau tik alerginis tyrimas gali tiksliai tai diagnozuoti. Jums rekomenduojame išsamų arba pirminį alergijos tyrimą.',
      },
      hint: 'Įvertinimas gali padėti nuspręsti ar verta išsitirti dėl alergijos, tačiau neturėtų būti naudojamas kaip medicinė diagnozė.',
    },
    symptoms: {
      title: 'Jūsų nurodyti simptomai',
      noSymptomsIndicated: 'Simptomai nenurodyti',
      noDetails: 'Neįvardinta',
      items: {
        often: 'Dažni',
        common: 'Įprasti',
        rare: 'Reti',
      },
      hint: 'Alergija gali pasireikšti įvairiais simptomais. Kai kurie simptomai yra specifiški ir pasireiškia dažniausiai alergijų metu, kiti dažniau pasireiškia kitų ligų atvejų. Simptomų specifiškumas padeda nustatyti alergijos riziką.',
    },
    periodicity: {
      title: 'Periodiškumas',
      hint: 'Periodiškai atsirandatys simptomai yra dažnas alerginių reakcijų požymis. Jei pastebite periodiškumą alergijos tikimybė yra didesnė.',
    },
    circumstances: {
      title: 'Aplinkybės',
      hint: 'Greitojo tipo reakcijos pasireiškia iš karto po santykio su alergenu, todėl jei pastebite aplinkybes, po kurių pasireiškia simptomai, tai padidina alergijos tikimybę.',
    },
    duration: {
      title: 'Simptomų trukmė',
      items: {
        someMonth: 'Kelis mėnesius',
        justNow: 'Tik ką',
        fiveYear: '5+ metai',
      },
      hint: 'Jei simptomai pasireiškia ir kartojasi ilgiau, tikimybė, kad tai alergijos provokuojami simptomai yra didesnė.',
    },
  },
  test_answer: {
    navigation: {
      allergyProfile: 'Alergijos profilis',
      recommendations: 'Rekomendacijos',
      allergens: 'Visi alergenai',
    },
    hello: 'Sveiki,',
    hero_subtitle: 'Jums buvo atlikta išsami alergijų vertinimo programa.',
    hero_text: 'Jūs apklausoje nurodėte {num} Jus {tiring} ir galimai su alergijomis {relatedSymptoms}',
    yourAnswerIsNotReady: 'Džiaugiamės, kad domitės, tačiau Jūsų atsakymas dar neparuoštas.',
    youAreSensitive: 'Jums nustatytas įsijautrinimas',
    noSensitivity: 'Jums įsijautrinimas nenustatytas',
    allergenGroupPlural: 'alergenų grupei|alergenų grupėms|alergenų grupių',
    origin_of_symptoms: 'Simptomų kilmė',
    here_we_review_diseases_and_symptoms:
      'Čia apžvelgsime, kokias ligas ir simptomus galimai kelia alerginės reakcijos.',
    typical_symptoms: 'Būdingi simptomai',
    allergens: 'Alergenai',
    possibly_provoking_symptoms: 'Galimai provokuojantys simptomus',
    interpretation_of_sensitivity_intensity: 'Įsijautrinimo stiprumo interpretacija',
    no_symptoms: 'Susijusių simptomų neįvardinta',
    unclear_origin: 'Nenustatytos kilmės',
    doctorPosition: {
      LINAS: 'vyr. alergologas ir klinikinis imunologas',
      ANA: 'Gydytoja alergologė',
      EGLE: 'Gydytoja alergologė',
      AGNE: 'Medicinos gydytoja, vyr. alergologijos ir klinikinės imunologijos rezidentė',
      KESTUTIS: 'Gydytojas alergologas',
      LAIMA: 'Alergologė ir klinikinė imunologė',
      GABIJA: 'Medicinos gydytoja',
    },
    detailedAllergenProfile: {
      title: 'Detalus alergenų profilis',
      description:
        'Čia detaliai apžvelgsime kokias galimas alergijas atradome iš kraujo tyrimo ir paaiškinsime jų ryšius bei svarbą Jums.',
    },
    unidentifiedSymptoms: {
      title: 'Nenustatytos kilmės simptomai',
    },
    primaryAllergen: {
      title: 'Pagrindinis alergenas',
      tooltip:
        'Alergenas, kuris laikomas savo grupės (pvz. medžių žiedadulkių) atstovu, ir dažniausiai sukelia alergines reakcijas. Šiam alergenui dažniausiai pirmiausia išsivysto alergija.',
    },
    noPrimaryAllergen: 'Nėra pagrindinio alergeno',
    components: {
      title: 'Komponentai',
      onlyInFullResult: 'Atskiri alergenų komponentai yra nustatomi tik išsamiu molekuliniu alergijos ištyrimu',
      tooltip:
        'Iš tikrųjų, žmogus būna įsijautrinęs ne visam alergenui, o jo sudėtinėms dalims - komponentams. Vienas alergenas paprastai turi kelis skirtingus komponentus, tačiau ne visos jo sudedamosios dalys pasižymi alergiją gebančiomis sukelti savybėmis. Žmogus gali būti įsijautrinęs tiek vienam, tiek keliems skirtingiems to alergeno komponentams. Dažniausiai komponentai yra baltymai. Komponentai žymimi simboliais, kurie sudaryti iš lotyniško alergeno pavadinimo trumpinio ir skaičiaus. Pvz.: beržo (Betula verrucosa) žiedadulkių pirmas komponentas žymimas - Bet v 1. Komponentai padeda nustatyti, kurie alergenai yra pirminiai, prognozuoti tam tikrų alerginių reakcijų riziką ir padeda parinkti specifinį gydymą.',
    },
    secondaryAllergens: {
      title: 'Kiti siejami alergenai',
      onlyInFullResult:
        'Susijusius alergenus ir kryžmines reakcijas galime nustatyti tik išsamiu molekuliniu alergijos ištyrimu',
      tooltip:
        'Alergenai, kurie turi struktūrinių panašumų į pagrindinį grupės alergeną, todėl taip pat gali sukelti alerginius simptomus. Dažniausiai šiems alergenams jautrumas atsiranda vėliau nei pagrindiniam alergenui.',
    },
    relatedSymptoms: {
      title: 'Galimi simptomai nuo šių alergenų',
      tooltip:
        'Čia pateikiame visus galimus simptomus, kurie gali būti sukeliami šių alergenų. Paryškintus simptomus Jūs pažymėjote klausimyne kaip varginančius',
    },
    activePeriod: {
      title: 'Aktualus laikotarpis',
      tooltip: 'Aukščiau paminėti alergenai dažniausiai pasireiškia žemiau paminėtu laikotarpiu.',
      months: 'Paryškinti mėnesiai yra aktualūs',
    },
    viewAllAllergens: 'Peržiūrėti visus {num} testuotus alergenus',
    viewRecommendations: 'Peržiūrėti rekomendacijas',
    symptoms: 'Simptomai',
    tiringPlural: 'varginantį|varginančius|varginančių',
    relatedSymptomsPlural: 'susijusį simptomą|susijusius simptomus|susijusių simptomų',
    provokePlural: 'provokuojamas|provokuojami|provokuojami',
    symptomsPlural: 'simptomas|simptomai|simptomų',
    no_allergens: 'Provokuojančių alergenų nerasta',
    sensitivity: [
      'Nėra įsijautrinimo',
      'Lengvas įsijautrinimas',
      'Vidutinis įsijautrinimas',
      'Aukštas įsijautrinimas',
      'Aukštas įsijautrinimas',
    ],
    sensitivityShort: ['Nėra', 'Lengvas', 'Vidutinis', 'Aukštas', 'Aukštas'],
    sensitivitySuffix: ', kuris gali provokuoti ',
    manySymptoms: 'daug simptomų',
    noSymptoms: ', kuris neturėtų provokuoti simptomų',
    noSensitivityAllergensListTitle: 'Jums buvo atliktas tyrimas, įvertinęs galimą įsijautrinimą {num} alergenams',
  },
  recommendations: {
    title: 'Rekomendacijos',
    titleActions: 'Pradėkite nuo šių rekomendacijų',
    titleAll: 'Visos rekomendacijos',
    otherRecommendations: 'Kitos rekomendacijos',
    description:
      'Pradžiai atrinkome Jums kelias gyvenimo būdo ir gydymo rekomendacijas, kurios gali pagerinti Jūsų gyvenimo kokybę. Išbandykite jas ir pažymėkite ar jaučiate pagerėjimą. Remdamiesi Jūsų atgaliniu ryšiu atnaujinsime rekomendacijas, siekdami jas pritaikyti Jums. Atkreipkite dėmesį, kad dėl kai kurių rekomendacijų taikymo būtina pasitarti su gydytoju alergologu.',
    testedGood: 'Įtraukite šią rekomendaciją į savo gyvenseną',
    testedBad: 'Ieškosime kitų būdų, kaip padėti',
    testedTime: 'Išbandyta {date}',
    button: {
      work: 'Veikia',
      notWork: 'Neveikia',
      testedWork: 'Išbandyta',
      testedNotWork: 'Išbandyta',
    },
  },
  textPage: {
    remoteConsultation: {
      title: 'Nuotolinės konsultacijos taisyklės',
    },
    privacyPolicy: {
      title: 'Privatumo politika',
    },
    termsAndConditions: {
      title: 'Paslaugų teikimo sąlygos',
    },
    signAgreement: {
      title: 'Sutikimo pasirašymas',
    },
  },
  bloodRegistration: {
    title: 'Pasirinkite kliniką ir laiką kraujo paėmimui',
    selectClinicStep: 'Pasirinkite kliniką',
    pickerPlaceholder: 'Rinktis...',
    completedTitle: 'Puiku!',
    completedLocation: 'Jūs pasirinkote {title} ({address}).',
    completedFurtherAction: 'Paskambinkite klinikai telefonu:',
    completeFurtherActionSuffix: 'ir susitarkite laiką vizitui.',
    pickUpBoxPlaceholder: 'Ieškoti paštomato...',
  },
  dashboard: {
    orderNewItem: 'Užsakyti naują programą',
    title: 'Kas toliau?',
    noOrders: 'Panašu, kad neturite nei vieno užsakymo',
    descriptionNoBloodRegistration: 'Jums toliau reikėtų užsiregistruoti pasirinktoje klinikoje ir priduoti kraują.',
    descriptionNoBlood: 'Jums liko priduoti kraują ir tuomet mes galėsime atlikti tyrimą ir įvertinti jo rezultatus.',
    tasks: {
      orderCompletedHeading: 'Jūsų rezultatas paruoštas',
      orderCompletedDescription:
        'Mūsų specialistai atliko kraujo tyrimą ir įvertino jo rezultatus. Jau galite peržiūrėti tyrimo atsakymą',
      orderCompletedDescriptionConsultation: 'Mūsų specialistai paruošė Jums rekomendacijas.',
      incompleteHeading: 'Atlikite šias užduotis',
      completedHeading: 'Atlikote visas užduotis!',
      description:
        'Mūsų specialistams atlikus tyrimą informuosime jus elektroniniu paštu {email}. Tyrimo atsakymą galėsite rasti skiltyje aukščiau “Atsakymas”.',
    },
    bloodRegistration: {
      incompleteTitle: 'Užsiregistruokite kraujo paėmimui',
      incompleteDescription:
        'Kraujo tyrimo metu bus atliekamas išsamus 295 alergenų testas bei iki 10 patikslinamųjų tyrimų ',
      completed: 'Registracija kraujo paėmimui sėkminga',
      callClinicByPhone: 'Paskambinkite klinikai telefonu {phone} ir susitarkite Jums patogų atvykimo laiką.',
      completedDescription:
        'Kraujo paėmimo paslauga jums nekainuos papildomai. Klinikoje nurodykite, kad atvykote kraujo paėmimui Allergomedica alergijų programai ir parodykite registraciją patvirtinantį el. laišką.',
      selectedClinic: 'Jūsų pasirinkta klinika:',
      selectedTime: 'Jūsų registracijos laikas:',
      button: 'Registruotis',
    },
    research: {
      completed: 'Klausimynas užpildytas sėkmingai',
      description: 'Priduokite kraują jeigu dar jo nepridavėte ir mes Jus informuosime kai bus paruoštas atsakymas',
    },
    orderedAt: 'Užsakytas:',
    orderId: 'Užsakymo numeris:',
    status: {
      ongoing: 'Vykdomas',
      completed: 'Įvykdytas',
    },
    order: 'Užsakymas',
    resultPreview: 'Peržiūrėti atsakymą',
  },
  form: {
    password: 'Slaptažodis',
    email: 'El. paštas',
    surname: 'Pavardė',
    name: 'Vardas',
    phone: 'Telefonas',
    phoneNo: 'Telefono numeris',
    birthDate: 'Gimimo data',
    placeholderBirthDate: 'Įrašykite gimimo datą',
    birthDateFormat: 'Datą rašykite atskirdami brūkšneliais, pvz.: 1999-12-08',
    gender: 'Lytis',
    genderSelect: 'Pasirinkite lytį',
    genderMale: 'Vyras',
    genderFemale: 'Moteris',
    genderPickerPlaceholder: 'Pasirinkite lytį',
    personCode: 'Asmens kodas',
    newPassword: 'Naujas slaptažodis',
    repeatPassword: 'Pakartokite slaptažodį',
    YourPersonalIdNo: 'Jūsų asmens kodas',
    YourChildCode: 'Jūsų vaiko asmens kodas',
    spouseName: 'Sutuoktinio (-ės) vardas',
    spouseSurname: 'Sutuoktinio (-ės) pavardė',
    spouseEmail: 'Sutuoktinio (-ės) el. paštas',
    address: 'Adresas',
    city: 'Miestas',
    zipCode: 'Pašto kodas',
    atLeastEightSymbols: 'Mažiausiai 8 simboliai',
    agreementForScienceResearch:
      'Aš sutinku, kad mano nuasmeninta informacija ir kraujo mėginys būtų naudojami mokslo tikslams',
    alertSuccessAnswer: {
      title: 'Registraciją sėkminga',
      text: 'Ačiū! Jūsų patogumui, nuoroda į atsakymą rasite nurodytame el. pašte.',
    },
    alertDangerAnswer: {
      title: 'Užsiregistruoti nepavyko',
      text: 'Įvedėte klaidingą el. pašto adresą.',
    },
    requiredFields: '* pažymėti laukai yra privalomi.',
  },
  diForm: {
    title: 'Įveskite dalyvio kodą',
  },
  checkout: {
    success_title: 'Užsakymas sėkmingas',
    success_text: 'Mokėjimą patvirtinanti laišką gavote nurodytu el. pašto adresu',
    sign_success_title: 'Dokumentas pasirašytas sėkmingai',
    sign_success_text: 'Pasirašymą patvirtinanti laišką gavote nurodytu el. pašto adresu',
    titleContactInfo: 'Kontaktinė informacija',
    titleSpouseContactInfo: 'Sutuoktinio (-ės) kontaktinė informacija',
    titleChooseBank: 'Pasirinkite mokėjimo būdą',
    titleInfoForRemoteConsultation: 'Informacija nuotolinei konsultacijai',
    explanationTextInfoForRemoteConsultation:
      'Asmens kodas reikalingas receptinių vaistų išrašymui ir jūsų konsultacijos suvedimui į e.sveikatą.',
    explanationTextInfoForChildsResearch:
      'Vaikams iki 12 metų reikia abiejų tėvų sutikimo. Nuorodą elektroniniam dokumento pasirašymui atsiųsime nurodytu el. pašto adresu.',
    failed: {
      title: 'Užsakymas nepavyko',
      description: 'Nepavyko gauti Jūsų apmokėjimo. Prašome bandyti dar kartą, arba susisiekti su mumis',
    },
    loginTitle: 'Prisijunkite prie savo paskyros',
    loginDescription:
      'Jau esate pas mus registravęsi seniau, prisijunkite prie savo paskyros ir joje galėsite sekti savo programos būseną bei atlikti tolimesnius veiksmus',
    titleYourOrder: 'Jūsų užsakymas',
    titleBilling: 'Atsiskaitymas',
    hide: 'Paslėpti',
    iHaveDiscount: 'Turiu nuolaidos kodą',
    showOrder: 'Rodyti užsakymą',
    discount: 'Nuolaida',
    subTotal: 'Tarpinė suma',
    discountCode: 'Nuolaidos kodas',
    amount: 'Mokėtina suma',
    checkboxIHaveReadAnd: 'Aš perskaičiau ir sutinku su',
    checkboxPrivacyPolicy: 'Privatumo politika',
    checkboxAnd: 'ir',
    checkBoxTermsOfService: 'Paslaugų teikimo taisyklėmis',
    checkboxIAgreeThatImunodiagnostika:
      'Sutinku, kad Imunodiagnostika, UAB tvarkytų mano sveikatos duomenis, kad galėtų sukurti paskyrą, atlikti testus, bei pateikti sveikatos vertinimą, bei rekomendacijas.',
    checkboxIAgreeToGetDoctorAdvice:
      'Sutinku gauti gydytojo patarimus kaip gyventi su alergijomis, sveikatos naujienas ir specialius pasiūlymus. Jūs visada galėsite lengvai atsisakyti naujienų prenumeratos',
    checkboxPayseraText:
      'Informuojame, kad sąskaitos informacijos ir mokėjimo inicijavimo paslaugas Jums suteiks Paysera vadovaudamasi šių paslaugų teikimo',
    checkboxPayseraTerms: 'taisyklėmis',
    checkboxPayseraAgreement:
      '. Tęsdami apmokėjimą patvirtinate, kad sutinkate su šių paslaugų suteikimu ir jų teikimo sąlygomis.',
    checkboxAgreeRemoteConsultation: 'Aš perskaičiau ir sutinku su ',
    checkboxAgreeRemoteConsultationSuffix: 'nuotolinės konsultacijos taisyklėmis.',
    checkboxAgreeChildResponsibility: 'Aš patvirtinu, kad esu įgaliotas asmuo priimti sveikatos sprendimus už vaiką.',
    previewProfile: 'Į profilį',
    titleResearch: 'Sutikimo pasirašymas',
    SigningOfDocumentsDokobit: 'Dokumentų pasirašymas vyks per „Dokobit“ sistemą.',
  },
  registration: {
    title: 'Susikurkite paskyrą',
    text: 'Susikūrę paskyrą galėsite atlikti tolimesnius veiksmus su savo programa, o vėliau gausite ir tyrimo rezultatus',
    haveAccount: 'Jau turite paskyrą?',
    login: 'Prisijunkite',
  },
  login: {
    title: 'Prisijunkite',
    forgotYourPasswordLink: 'Pamiršote slaptažodį?',
    dontHaveAccount: 'Neturite paskyros?',
    register: 'Užsiregistruokite',
  },
  forgotPassword: {
    requestPasswordReset: 'Slaptažodžio priminimas',
    successTitle: 'Slaptažodžio priminimo nuoroda nusiųsta Jūsų įvestu el.pašto adresu',
    finishPasswordReset: 'Pasikeiskite slaptažodį',
    changePasswordSuccessTitle: 'Slaptažodis atnaujintas',
    linkToLogin: 'Grįžti į prisijungimą',
  },
  selectPatient: {
    title: 'Pasirinkite kam bus atliekamas ištyrimas:',
    label: 'Pasirinkite iš žemiau esančio sąrašo arba pridėkite naują.',
    labelRadio: 'Pasirinkite pacientą arba susikurkite naują',
    addNewPatient: 'Pridėti naują pacientą',
    button: 'Tęsti',
  },
  createPatient: {
    title: 'Papildoma informacija apie pacientą',
    label: 'Ištyrimas atliekamas:',
    addNewPatient: 'Pridėti naują pacientą',
    button: 'Tęsti',
    agreementForSienceResearch: 'Tęsti',
  },
  createPatientChild: {
    title: 'Papildoma informacija apie jūsų vaiką',
    label: 'Ištyrimas atliekamas:',
    addNewPatient: 'Pridėti naują pacientą',
    button: 'Tęsti',
  },
  navigation: {
    orders: 'Užsakymai',
    result: 'Atsakymas',
    consultations: 'Klauskite',
    yourProfile: 'Mano profilis',
    signOut: 'Atsijungti',
    selectItem: 'Pasirinkite puslapį',
    comingSoon: 'Jau greitai',
    openMainMenu: 'Atidaryti pagrindinį meniu',
  },
  profileAllergens: {
    weHaveEvaluated: 'Iš viso įvertinome',
    allergensTotal: ' alergenus',
    footnotes: {
      prefix: 'Kraujo tyrimo duomenų šaltinį galite rasti',
      link: 'čia',
      suffix: '(atsakymą galėsite pasižiūrėti suvedę paciento gimimo datą)',
    },
    table: {
      allergen: 'Alergenas',
      component: 'Komponentas',
      biochemicalDesignation: 'Baltymų grupė',
      value: 'Reikšmė',
      strength: 'Stiprumas',
    },
  },
  legend: {
    title: 'Įsijautrinimo stiprumo interpretacija',
    description:
      'Įsijautrinimo stiprumas tiesiogiai nenurodo ar reaguojantis alergenas tikrai sukels simptomus. Tačiau kuo stipresnis įsijautrinimas, tuo didesnė tikimybė, kad šis radinys yra kliniškai reikšmingas.',
    senseHigh: 'Aukštas įsijautrinimas (>3.5 kU/l)',
    senseMedium: 'Vidutinis įsijautrinimas (0.7 - 3.49 kU/l)',
    senseLow: 'Lengvas įsijautrinimas (0.35 - 0.69 kU/l)',
    senseNone: 'Nėra įsijautrinimo (0 - 0.34 kU/l)',
    noNum: {
      senseHigh: 'Aukštas įsijautrinimas',
      senseMedium: 'Vidutinis įsijautrinimas',
      senseLow: 'Lengvas įsijautrinimas',
      senseNone: 'Nėra įsijautrinimo',
    },
  },
  order: {
    steps: ['Užpildykite klausimyną', 'Apmokėkite', 'Priduokite kraują', 'Gaukite atsakymą'],
    stepsConsultation: [
      'Užpildykite klausimyną',
      'Apmokėkite',
      'Susiskambinkite su gydytoju',
      'Gaukite rekomendacijas',
    ],
    stepsResearch: ['Užpildykite klausimyną', 'Pasirašykite sutikimą', 'Priduokite kraują', 'Gaukite atsakymą'],
    stepsImmunotherapy: ['Užpildykite klausimyną', 'Apmokėkite', 'Priduokite kraują', 'Sudarysime gydymo planą'],
  },
  chat: {
    inputPlaceholder: 'Jūsų žinutė...',
    heading: 'Turite klausimų apie savo alergiją?',
    headingHasDr: 'Klausimai?',
    description: 'Užduokite juos mūsų specialistams',
    descriptionHasDrPlural: 'Jūsų gydytojas|Jūsų gydytojai',
    replyTimeDescription: 'Dažniausiai atsakome per kelias valandas',
    doctorsTitle: 'Mūsų gydytojų komanda',
    consultation: {
      title: 'Nuotolinė konsultacija',
      description: 'Norite aptarti klausimus nuotolinės konsultacijos metu? Pirma konsultacija - nemokamai.',
      button: 'Registruotis',
    },
  },
  questionnaireConsultationIntro: 'Užtikriname, kad konsultaciją gausite per 2 darbo dienas nuo užsakymo apmokėjimo',
  questionnaireGenericIntro:
    'Užpildykite klausimyną ir mes patarsime, kuri Allergomedica alergijos ištyrimo programa jums labiausiai tinka',
  questionnaireCheckAllergyIntro:
    'Įtariate, kad esate alergiški? Šis klausimynas padės įvertinti tikimybę, kad jūsų simptomai yra sukeliami greitojo tipo alerginės reakcijos.',
  questionnaireDuration: 'Apklausa užtruks iki 5 min.',
  doctors: [
    {
      name: 'Linas Griguola',
      nameAccusative: 'Lino Griguolos',
      role: 'vyr. alergologas ir klinikinis imunologas',
      subrole: '„Allergomedica“ vyr. gydytojas',
      imageUrl: 'LINAS',
      infoLink: 'linas-griguola',
    },
    {
      name: 'Ana Kozlovska',
      nameAccusative: 'Anos Kozlovskos',
      role: 'Gydytoja alergologė ir klinikinė imunologė; gydytoja vaikų alergologė',
      subrole: '„Allergomedica“ gydytoja',
      imageUrl: 'ANA',
      infoLink: 'ana-kozlova',
    },
    {
      name: 'Eglė Žilėnaitė',
      role: 'Gydytoja alergologė ir klinikinė imunologė',
      subrole: '„Allergomedica“ gydytoja',
      imageUrl: 'EGLE',
      infoLink: 'egle-zilenaite',
    },
    {
      name: 'Kęstutis Černiauskas',
      role: 'Gydytojas alergologas ir klinikinis imunologas',
      subrole: '„Allergomedica“ gydytojas',
      imageUrl: 'KESTUTIS',
      infoLink: 'kestutis-cerniauskas',
    },
    {
      name: 'Laima Aleksandravičiūtė',
      role: 'Alergologė ir klinikinė imunologė',
      subrole: '„Allergomedica“ gydytoja',
      imageUrl: 'LAIMA',
      infoLink: 'laima-aleksandraviciute',
    },
  ],
  reviewsBanner: {
    title: 'Kaip vertinate {companyName}?',
    text: 'Būtume labai dėkingi, jei pasidalintumėte savo mintimis. Užtruksite 1-2 minutes.',
    button: 'Plačiau',
    dismiss: 'Uždaryti',
  },
  consultationBanner: {
    title: 'Norite rasti būdą sumažinti ar išsigydyti simptomus? Ar tiesiog pasitarti?',
    text: 'Pridėkite gydytojo nuotolinę konsultaciją pilnaverčiam ištyrimui. Jos metu galėsite aptarti\ntyrimo rezultatus ir sudaryti gydymo planą. Tai galėsite padaryti užsakymo procese.',
  },
  feedback: {
    success_title: 'Ačiū, kad padedate mums tobulėti!',
    success_text: 'Jūsų atsakymas sėkmingai išsiųstas.',
  },
  consultationSuccess: {
    success_title: 'Ačiū!',
    success_text: 'Jūsų registracija sėkminga',
  },
  calendar: {
    title: 'Gydytojo laisvi laikai',
    freeTime: 'laikai',
    titleFreeTime: 'Laisvi laikai - ',
    previousMonth: 'Praeitas mėnuo',
    nextMonth: 'Sekantis mėnuo',
    noAppointments: 'Nėra laisvų laikų',
    schedule: 'Tvarkaraštis skirtas',
    showDates: 'Rodyti datas',
    weekDays: {
      M: 'P',
      Tue: 'A',
      W: 'T',
      T: 'K',
      F: 'P',
      S: 'Š',
      Su: 'S',
    },
  },
  immunotherapy: {
    titleTreatment: 'Sutikimas pradėti gydymą',
    titleAddress: 'Adresas vaistų pristatymui',
    subTitleAddress: 'Kokiu adresu pageidaujate gauti vaistus?',
    form: {
      terms: {
        firstRow: 'Sutinku, kad man būtų skiriamas ASIT gydymas.',
        firstRowChild: 'Sutinku, kad mano vaikui būtų skiriamas ASIT gydymas.',
        secondRow: 'Esu susipažinęs su ',
        secondRowLinkText: 'gydymo protokolu.',
        items: [
          'Suprantu numatomo gydymo tikslą, jo trukmę, efektyvumą bei nepageidaujamų reakcijų atsiradimo galimybę;',
          'Informavau apie savo ligas ir vartojamus vaistus;',
          'Uždaviau rūpimus klausimus susijusius su šiuo gydymo metodu ir gavau atsakymus;',
        ],
        itemsChild: [
          'Suprantu numatomo gydymo tikslą, jo trukmę, efektyvumą bei nepageidaujamų reakcijų atsiradimo galimybę;',
          'Informavau apie vaiko ligas ir vartojamus vaistus;',
          'Uždaviau rūpimus klausimus susijusius su šiuo gydymo metodu ir gavau atsakymus;',
        ],
      },
    },
    successText: 'Apklausos duomenys išsaugoti.',
    treatmentPlan: {
      title: 'Gydymo planas',
      titleDuration: 'Iki gydymo plano peržiūrėjimo liko',
      subtitleAllergenPlural: 'Alergenas|Alergenai',
      subtitleSymptoms: 'Simptomai',
      subtitleDrugsForSymptoms: 'Vaistai simptomams malšinti',
    },
    treatmentProgress: {
      title: 'Gydymo progresas',
      titleSymptoms: 'Simptomų stiprumas',
      titleProgressBlock: 'Bendras progresas',
      titleChart: 'Simptomų stiprumo pokytis laike',
      chartText: 'Gydymo progresas %',
    },
    treatmentDayPlural: 'diena|dienos|dienų',
    tabs: {
      ask: 'Klausk',
      progress: 'Progresas',
      plan: 'Planas',
    },
  },
  anamnesis: {
    questionnaire: {
      description:
        'Prieš atvykdami į viztą, užpildykite anketą apie jūsų patiriamus simptomus. Tai leis greičiau įsigilinti į jūsų situaciją ir geriau išnaudoti konsultacijai skirtą laiką.',
      submit: {
        title: 'Išsiųsti anketos duomenis',
        privacypolicy1: 'Spausdami išsiųsti, Jūs sutinkate pasidalinti duomenimis su',
        privacypolicy1IsSecond: 'Spausdami baigti, Jūs sutinkate pasidalinti duomenimis su',
        privacypolicy2: 'ir sutinkate su',
        privacypolicyLinkTex: 'sistemos privatumo politika',
        termsAndConditions1: 'bei ',
        termsAndConditionsLinkText: 'naudojimosi taisyklėmis.',
        isSecond: {
          title: 'Ačiū, šiuo atveju klausimyno pildyti nereikia',
          title2: 'Gydytojas jau yra susipažinęs su jūsų situacija.',
        },
      },
      successTitle: 'Jūsų klausimynas sėkmingai persiųstas gydytojui',
      error: 'Išsiųsti nepavyko',
      upload: {
        title: 'Įkelkite papildomą informaciją',
        description: 'Įkelkite papildomą informaciją, kuri padės gydytojui greičiau įvertinti jūsų situaciją:',
        list: [' Patiriamų odos simptomų nuotraukas.', 'Alergijos tyrimų rezultatus'],
        success: 'Jūsų duomenys sėkmingai buvo persiųsti gydytojui',
        noPatientTitle: 'Klaidinga nuoroda.',
        noPatientText: 'Susisiekite su Jus konsultuojančiu gydytoju.',
      },
    },
  },
  selectConsultationReview: {
    title: 'Pasirinkite, kaip norite įvertinti tyrimo rezultatus:',
  },
  treatmentProtocol: {
    title: 'Gydymo protokolas',
  },
  locations: {
    workingHours: 'Darbo laikas:',
  },
  meta: {
    description:
      'Pažangiausia alerginių susirgimų diagnostika ir gydymas. Alergijos tyrimai ir alergenų testai neišeinant iš namų. Allergomedica - gyvenimui be alergijos.',
    title: 'AllergoMedica - Modernus būdas suprasti savo alergiją.',
    url: 'https://app.allergomedica.lt',
  },
  footer: {
    companyInfo: 'Imunodiagnostika, UAB įmonės kodas: 303064228',
    address: 'Molėtų g. 16, Didžiosios Riešės k., LT-14260 Vilniaus r.',
    phone: '+370 640 65680',
    email: 'info@allergomedica.lt',
  },
  FOOD_ID: {
    test_answer: {
      navigation: {
        allergyProfile: 'Apžvalga',
        recommendations: 'Rekomendacijos',
        allergens: 'Tyrimo atsakymas',
      },
      hello: 'Sveiki,',
      hero_subtitle: 'Jūsų situacijos apžvalga',
      hero_text: 'Jūs apklausoje nurodėte {num} Jus {tiring} ir galimai su alergijomis {relatedSymptoms}',
      summary: {
        weight: 'Svoris',
        kg: 'kg',
        height: 'Ūgis',
        cm: 'cm',
        bmi: 'Masės indeksas',
        BMI: 'KMI',
      },
      youAreSensitive: 'Jums nustatytas įsijautrinimas',
      noSensitivity: 'Jums nenustatytas įsijautrinimas tirtiems maisto produktams.',
      allergenGroupPlural: 'maisto produktui|maisto produktams|maisto produktų.',
      yourSymptoms: 'Jūsų simptomai',
      profileAllergens: {
        weHaveEvaluated: 'Iš viso ištyrėme įsijautrinimą',
        allergensTotal: 'maisto produktams',
        table: {
          allergen: 'Maisto produktas',
          strength: 'Klasė / įsijautrinimo stiprumas',
        },
      },
      habits: {
        youAreSensitiveStaticTitle:
          'Jums nustatyti mitybos ir gyvenimo būdo įpročiai, kurie gali sukelti jūsų simptomus',
        youAreSensitivePlural: 'Jums nustatytas|Jums nustatyti|Jums nustatyta',
        noHabits: 'Jums nenustatyta mitybos ir gyvensenos įpročių, kurie gali provokuoti simptomus.',
        habitsPlural: 'mitybos ir gyvensenos įprotis|mitybos ir gyvensenos įpročiai|mitybos ir gyvensenos įpročių.',
        canProvokeSymptoms: ', kurie gali provokuoti simptomus.',
        canProvokeYou: 'Gali provokuoti jums šiuos simptomus',
        canProvokeYouPlural: 'Gali provokuoti jums šiuos simptomus',
        symptoms: 'simptomus',
      },
      symptoms: {
        title: 'Apžvelkime jūsų nurodytus simptomus',
        titleHabitsThatCanProvokeSymptom: 'Įpročiai, kurie gali provokuoti šį simptomą:',
        titleFoodThatCanProvokeSymptom: 'Maisto produktai, kurie gali provokuoti šį simptomą:',
        titleOtherFactors: 'Kiti faktoriai:',
        factoriesWhich: 'Faktoriai, kurie',
      },
    },
    recommendations: {
      title: 'Rekomendacijos',
      description:
        'Specialiai jums atrinkti mitybos ir gyvensenos įpročių pokyčiai, kurie galėtų prisidėti prie jūsų savijautos pagerėjimo.',
      titleProducts: 'Produktai, kurių vartojimą rekomenduojame sumažinti arba pakeisti kitais',
      titleProductSymptoms: 'Provokuojami simptomai:',
      titleHowToAvoid: 'Kaip to išvengti?',
      titleHowToFollowRecommendations: 'Kaip laikytis rekomendacijų?',
      textHowToFollowRecommendations:
        'Jeigu taikant šias rekomendacijas per 3-4 savaites varginantys simptomai nesumažėja ar per tą laikotarpį atsiranda aliarmo simptomų, nedelsdami kreipkitės į šeimos gydytoją arba gastroenterologą detalesniam įvertinimui.',
    },
    footer: {
      email: 'info@foodid.lt',
    },
    button: {
      showAll: 'Rodyti viską',
      showLess: 'Rodyti mažiau',
    },
  },
  testResults: {
    protocolNumber: 'Tyrimo protokolas nr.',
    testTitle: 'Tyrimo pavadinimas:',
    nameSurname: 'Vardas ir pavardė:',
    gender: 'Lytis:',
    birthDate: 'Gimimo data:',
    sampleDateTime: 'Ėminio paėmimo data ir laikas:',
    laboratoryDateTime: 'Ėminio gavimo į laboratoriją data ir laikas:',
    testDateTime: 'Tyrimo alikimo data ir laikas:',
    resultsDateTime: 'Rezultatų išdavimo, data ir laikas:',
    sampleTypes: 'Ėminio tipas:',
    sampleCode: 'Mėginio kodas:',
    method: 'Metodas:',
    testPerformedBy: 'Tyrimą atliko:',
    testResultsConfirmed: 'Rezultatus patvirtino:',
  },
  doctorSelection: {
    pickerPlaceholder: 'Rinktis gydytoją...',
    doNotWantToShare: 'Nenoriu dalintis informacija su gydytoju',
  },
};
